import React from "react";
import check from "../../assets/check.png";
import { Container, List } from "./TabContent.styles";

const Professor: React.FunctionComponent = () => {
  const checkIconStyle = {
    width: "24px",
    height: "24px",
    marginRight: "8px"
  };

  const listItemTextStyle = {
    marginBottom: "4px",

    fontWeight: "bold"
  };

  const ulStyle = {
    marginTop: "20px"
  };

  return (
    <Container style={{ marginLeft: "20px" }}>
      <p>
        <strong>
          Nossa plataforma oferece ao professor uma ferramenta completa para
          realização de avaliações formativas de forma facilitada. Eles têm
          acesso a diversos instrumentos avaliativos e podem inserir dados de
          avaliação por rubricas, notas de avaliação somativa, aplicar quizzes,
          desafios baseados na resolução de problemas, criar portfólios e muito
          mais.
        </strong>
      </p>
      <List>
        <ul style={ulStyle}>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="Check Icon" style={checkIconStyle} />
            <p style={listItemTextStyle}>Aprendizagem Ativa</p>
          </li>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="Check Icon" style={checkIconStyle} />
            <p style={listItemTextStyle}>Relatórios</p>
          </li>
        </ul>
        <ul style={ulStyle}>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="Check Icon" style={checkIconStyle} />
            <p style={listItemTextStyle}>Gamificação</p>
          </li>
          <li style={{ display: "flex", alignItems: "center" }}>
            <img src={check} alt="Check Icon" style={checkIconStyle} />
            <p style={listItemTextStyle}>BNCC e NEM</p>
          </li>
        </ul>
      </List>
    </Container>
  );
};

export default Professor;
