import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import Navbar from "./components/Navbar/Navbar";
import {
  Button,
  Header,
  HeaderContainer,
  Subtitle,
  Title
} from "./Header.styles";
import background from "../../assets/dots.png";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import NovaImagem from "../../assets/celular (1).png";

const MainHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)"
  });

  return (
    <Header
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        fontFamily: "Poppins, sans-serif"
      }}
    >
      {isMobile && (
        <BurgerMenu isOpen={isOpen} handleClick={() => setIsOpen(!isOpen)} />
      )}
      <Navbar
        name={isMobile && isOpen ? "active" : ""}
        handleClose={() => setIsOpen(false)}
      />
      <HeaderContainer>
        <div>
          <Title>
            Sapieduca: A Plataforma que facilita a avaliação e entrega de
            diagnósticos de aprendizagem mais precisos
          </Title>
          <Subtitle>
            Aprendizagem Ativa, Gamificação, Engajamento, Diagnósticos da
            Aprendizagem, Projeto de Vida e Learning Analytics na sua escola a
            um clique de distância!
          </Subtitle>
          <Link
            activeClass="active"
            to="contact"
            spy
            smooth
            offset={100}
            duration={500}
          >
            <Button>Saiba mais</Button>
          </Link>
        </div>
        <div>
          <img
            src={NovaImagem}
            alt="Nova Imagem"
            style={{ width: "400.94px", height: "510px", marginRight: "1px" }}
          />
        </div>
      </HeaderContainer>
    </Header>
  );
};

export default MainHeader;
