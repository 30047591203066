import styled from "styled-components";

export const ContactContainer = styled.form`
  min-height: 671px;
  box-sizing: border-box;
  padding: 180px 112px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 200px;

  @media (max-width: 1024px) {
    padding: 180px 24px 0;
    height: auto;
    margin: 50px;
    margin-top: 200px;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    alignitems: "center";
  }
`;

export const Subtitle = styled.span`
  font-style: roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 184.9%;
  color: #35ccd3;
  text-align: center;
  margin-bottom: 20px;
  margin-left: 46%; /* Setting a 10% margin to the left */
  margin-right: 20%; /* Setting a 10% margin to the right */
  font-family: "Poppins", sans-serif;
  magin: 250;
`;

export const Title = styled.h2`
  font-style: roboto;
  font-weight: 700;
  font-size: 36px;
  line-height: 100.7%;
  color: #3366cc;
  margin: 250;
  text-align: center;
  margin-bottom: 50px;
  margintop: "250px";
`;

export const Button = styled.button`
  width: 100%;
  max-width: 384px;
  height: 44px;
  background-color: #ea5f9e;
  border-radius: 24px;
  padding: 14px 0;
  border: none;
  color: white;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  transition: 0.3s ease-in-out;

  &:hover {
    filter: saturate(0.9);
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const InputGroup = styled.section`
  width: 100%;
  max-width: 1020px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  & > div {
    width: 100%;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  & > div:first-of-type {
    width: 488px;
    margin-right: 32px;

    @media (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const InputContainer = styled.section`
  position: relative;
  width: auto;
  margin-bottom: 32px;

  & > p {
    position: absolute;
    left: 24.8px;
    top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #495057;
    opacity: 0.8;
    margin: 0;
  }

  & .sapientia-select__control {
    width: 488px;
    height: 64px;
    background: #f6f6f8;
    border-radius: 8px;
    border: none;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  & .sapientia-select__indicator-separator {
    display: none;
  }
`;

export const Input = styled.input`
  width: 488px;
  height: 64px; /* Altura padrão */
  background: #f6f6f8;
  border-radius: 8px;
  border: none;
  padding-top: 20px;
  padding-left: 24.8px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 100% !important;
  }
`;

export const TextField = styled.textarea`
  width: 100%;
  height: 258px; /* Altura padrão */
  background: #f6f6f8;
  border-radius: 8px;
  border: none;
  padding-top: 20px;
  padding-left: 24.8px;
  box-sizing: border-box;
  resize: none;
  text-align: center;
  @media (max-width: 1024px) {
    width: 100% !important;
    margin-top: -200px;
  }
`;
